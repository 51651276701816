import React, { useState } from 'react';
import ReactPageScroller from "react-page-scroller";

import Page01 from './pages/Page01';
import Page02 from './pages/Page02';
import Page03 from './pages/Page03';
import Page05 from './pages/Page05';
import Page04 from './pages/Page04';
import Page06 from './pages/Page06';
import TopMenu from './components/TopMenu';
import Bottom from './components/Bottom';

export default function App() {
  const [pageNum, setPageNum] = useState(0);

  const handlePageChange = (number) => {
    setPageNum(number);
  };

  return (
    <>
      <TopMenu />
      <ReactPageScroller
        id="fullpage"
        blockScrollUp={false}
        blockScrollDown={false}
        animationTimer={600}
        animationTimerBuffer={100}
        customPageNumber={pageNum}
        pageOnChange={handlePageChange}
      >
        <Page01 />
        <Page02 />
        <Page03 />
        <Page04 />
        <Page05 />
        <Page06 />
        <Bottom />        
      </ReactPageScroller>
    </>
  );
}
